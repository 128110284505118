<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		scrollable
		@keydown.esc="$emit('onClose', dialog)"
		v-if="delay != random"
	>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialogConfirmacao" max-width="500">
			<v-card>
				<v-card-title class="text-h5">Confirmar exclusão da reserva?</v-card-title>
				<v-divider />
				<div class="text-center py-3">
					<v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
				</div>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="dialogConfirmacao = false"
					>Cancelar</v-btn>
					<v-btn
						:loading="carregando"
						color="primary"
						elevation="0"
						@click="excluirReserva(reserva)"
					>Excluir</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogReserva" persistent scrollable>
			<v-card>
				<v-card-title class="text-h5">Reserva de produtos</v-card-title>
				<v-divider />
				<v-card-text class="pa-4">
					<v-skeleton-loader v-if="carregando" type="table-tbody" />
					<v-simple-table v-else>
						<thead>
							<tr>
								<th>Empresa</th>
								<th>Cliente</th>
								<th>Quantidade</th>
								<th>Data</th>
								<th>Local</th>
								<th>Pedido</th>
								<th>Vendedor</th>
								<th v-if="permissao('venda_quebrar_reserva')"></th>
							</tr>
						</thead>
						<tbody v-if="dados">
							<tr v-for="(r,i) in dados" :key="i">
								<td>{{r.idempresa}}</td>
								<td>{{r.cliente}}</td>
								<td>{{r.quantidade}}</td>
								<td>{{r.datareserva}}</td>
								<td>{{r.local}}</td>
								<td>{{r.pedido}}</td>
								<td>{{r.vendedor}}</td>
								<td v-if="permissao('venda_quebrar_reserva')">
									<v-btn
										fab
										elevation="0"
										x-small
										color="error"
										@click="reserva = r, dialogConfirmacao = true"
									>
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td colspan="8" class="text-center">Nenhum reserva encontrada</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						dark
						class="px-5"
						large
						elevation="0"
						@click="dialogReserva = false"
					>Fechar</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-card elevation="0">
			<v-card-title class="text-h5 font-weight-bold">
				<v-row align="center">
					<v-col class="text-truncate primary--text">{{produto.idsubproduto}} - {{produto.descricao}}</v-col>
					<v-col cols="auto" class="text-end">
						<v-btn color="primary" text fab @click="$emit('onClose', dialog)">
							<v-icon size="36">mdi-close-thick</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-title>
			<v-divider />
			<v-card-text class="pt-5">
				<v-row>
					<v-col cols="12" md="8" order="2" order-md="1">
						<v-row>
							<v-col cols="12" v-if="produto.linkimagen1 || produto.linkimagen2 || produto.linkimagen3">
								<v-carousel height="80vh">
									<v-carousel-item v-if="produto.linkimagen1" :src="produto.linkimagen1"></v-carousel-item>
									<v-carousel-item v-if="produto.linkimagen2" :src="produto.linkimagen2"></v-carousel-item>
									<v-carousel-item v-if="produto.linkimagen3" :src="produto.linkimagen3"></v-carousel-item>
								</v-carousel>
							</v-col>
							<v-col cols="12">
								<div class="text-subtitle-1 font-weight-bold mb-3">Descrição</div>
								<v-divider class="mb-4" />
								<div class="font-weight-bold mb-3" v-if="produto.especificacoes">{{produto.especificacoes}}</div>
								<div class="mb-3" v-if="produto.detalhamento">{{produto.detalhamento}}</div>
								<div class="mb-3" v-else>Produto sem descrição detatalhada.</div>
								<div v-if="produto.fabricante">
									<strong>Fabricante:</strong>
									{{produto.fabricante}}
								</div>
								<div v-if="produto.cor && produto.cor != 'SEM COR DEFINIDA'">
									<strong>Cor:</strong>
									{{produto.cor}}
								</div>
								<div v-if="produto.altura">
									<strong>Altura:</strong>
									{{produto.altura}}
								</div>
								<div v-if="produto.largura">
									<strong>Largura:</strong>
									{{produto.largura}}
								</div>
								<div v-if="produto.comprimento">
									<strong>Comprimento:</strong>
									{{produto.comprimento}}
								</div>
								<div v-if="produto.peso">
									<strong>Peso:</strong>
									{{produto.peso}}
								</div>
							</v-col>
							<v-col cols="12" v-if="produto.sugestao">
								<div class="text-subtitle-1 font-weight-bold mb-3">Produtos relacionados</div>
								<v-divider class="mb-4" />
								<v-row>
									<v-col v-for="(p,i) in produto.sugestao" :key="i" cols="6" md="3">
										<v-hover v-slot="{ hover }">
											<v-card elevation="3" @click="$emit('onSearch', `${p.idsubproduto}`)">
												<v-img :src="p.imagem" height="180">
													<v-expand-transition>
														<v-sheet
															v-if="hover"
															color="primary"
															class="d-flex"
															style="opacity:0.85"
															height="180"
														>
															<v-icon size="60" class="mx-auto" color="white">mdi-cart-plus</v-icon>
														</v-sheet>
													</v-expand-transition>
												</v-img>
												<v-divider />
												<v-card-text
													class="font-weight-bold primary--text pa-2 text-truncate"
												>{{p.idsubproduto}} - {{p.produto}}</v-card-text>
											</v-card>
										</v-hover>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" md="4" order="1" order-md="2">
						<v-row>
							<v-col cols="12">
								<v-card dark color="primary" flat>
									<v-card-title class="text-h5">Venda</v-card-title>
									<v-divider />
									<v-card-text class="white--text">
										<div v-if="produto.promocao > 0" class="text-right">
											<span class="float-left text-h6 mt-3">
												<v-icon class="mr-1 mb-1">mdi-cash-multiple</v-icon>Preço
											</span>
											<v-badge icon="mdi-arrow-down" color="green" overlap>
												<div
													class="text-decoration-line-through text-subtitle-1"
												>{{produto.venda | formataDinheiro}}</div>
											</v-badge>
											<div class="font-weight-bold text-h6 mt-n2">{{produto.promocao | formataDinheiro}}</div>
										</div>
										<div v-else class="text-right">
											<span class="float-left text-h6">
												<v-icon class="mr-1 mb-1">mdi-cash-multiple</v-icon>Preço
											</span>
											<div class="font-weight-bold text-h6 mt-3">{{produto.venda | formataDinheiro}}</div>
										</div>
									</v-card-text>
								</v-card>
							</v-col>
							<v-col cols="12">
								<v-card dark color="primary" flat>
									<v-card-title class="text-h5">Detalhes</v-card-title>
									<v-divider />
									<v-card-text class="white--text">
										<div class="float-left">
											<div class="py-2 text-h6">
												<v-icon class="mr-1">mdi-dolly</v-icon>Estoque
											</div>
											<div class="py-2 text-h6">
												<v-icon class="mr-1">mdi-package-variant-closed</v-icon>Reserva
												<v-btn light fab x-small class="ml-3 ml-md-5" @click="dialogReserva = true">
													<v-icon color="primary">mdi-package-down</v-icon>
												</v-btn>
											</div>
										</div>
										<div class="text-right">
											<div class="py-2 text-h6">{{produto.estoque.toFixed(2)}} {{produto.embalagem}}(s)</div>
											<div class="py-2 text-h6">{{produto.reserva.toFixed(2)}} {{produto.embalagem}}(s)</div>
										</div>
									</v-card-text>
								</v-card>
							</v-col>

							<v-col cols="12" class="text-h5">
								<v-card dark color="primary" flat>
									<v-card-title class="text-h5">Estoque Geral</v-card-title>
									<v-divider />
									<v-card-text class="pa-1">
										<v-simple-table dense light>
											<thead>
												<tr>
													<th class="px-1 text-center">Filial</th>
													<th class="px-1">Local</th>
													<th class="px-1 text-center">Estoque</th>
													<th class="px-1 text-center">Reserva</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(e,i) in produto.estoquegeral" :key="i">
													<td class="px-1 text-center">{{e.idempresa}}</td>
													<td class="px-1">{{e.local}}</td>
													<td class="px-1 text-center">{{e.estoque}}</td>
													<td class="px-1 text-center">{{e.reserva}}</td>
												</tr>
											</tbody>
										</v-simple-table>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "VendaProdutoDialog",
	props: ["dialog", "produto", "random"],
	components: {
		CardDialog,
	},
	data: () => ({
		dialogConfirmacao: false,
		dialogReserva: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		carregando: false,
		dados: [],
		delay: 0,
		reserva: {},
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		listarReserva() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}venda/produto/reserva`, {
					idsubproduto: parseInt(this.produto.idsubproduto),
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		excluirReserva(reserva) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}venda/produto/reserva/excluir`, {
					idcontadorreserva: reserva.idcontadorreserva,
					idempresa: reserva.idempresa,
					idsubproduto: this.produto.idsubproduto,
					reserva: reserva.quantidade,
					reservatotal: this.produto.reserva,
					produto: this.produto.descricao,
				})
				.then((res) => {
					if (res.data) {
						this.listarReserva();
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível exluir reserva do produto!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível exluir reserva do produto!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
	},
	watch: {
		dialogReserva: function () {
			if (this.dialogReserva) {
				this.listarReserva();
			}
		},
	},
	beforeMount() {
		this.delay = this.random;
	},
};
</script>
